/* -------custom style------------ */
.hero-banner {
    background-image: url(https://jiliko.com.ph/wp-content/uploads/2022/09/banner.jpg);
    /* height: ; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 140px 0px 160px 0px;
    color: white;

}

.bg-overlay {
    background-color: #4d40ff ;
    opacity: 0.36;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    
    /* transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
}

.elementor-71 .elementor-element.elementor-element-8bdf8af {
    /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 140px 0px 160px 0px;
}

.hero-title {
    color: #FFFFFF;
    font-size: 60px;
    line-height: 1em;
    letter-spacing: -2px;
}

.elementor-button.elementor-size-lg {
    font-size: 18px;
    padding: 20px 0px;
    border-radius: 5px;
    background-color: white;
    color: #F16468;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
.elementor-button.elementor-size-lg:hover {
   color:  white;
    background-color: #F16468;
}

/* -------custom style end------------ */

.elementor-image-box-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    color: white;
}

.join-jiliko {
    z-index: 100;
    font-size: 2em;
    color: white;

}

/* popular games */
/*! elementor - v3.7.7 - 20-09-2022 */
.elementor-column .elementor-spacer-inner {
    height: var(--spacer-size)
}

.e-container {
    --container-widget-width: 100%
}

.e-container>.elementor-widget-spacer {
    width: var(--container-widget-width, var(--spacer-size));
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.e-container>.elementor-widget-spacer>.elementor-widget-container,
.e-container>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer {
    height: 100%
}

.e-container>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner {
    height: var(--container-widget-height, var(--spacer-size))
}

/*! elementor - v3.7.7 - 20-09-2022 */
.elementor-image-gallery .gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    margin: 0 auto
}

.elementor-image-gallery .gallery-item img {
    margin: 0 auto
}

.elementor-image-gallery .gallery-item .gallery-caption {
    margin: 0
}

.elementor-image-gallery figure img {
    display: block
}

.elementor-image-gallery figure figcaption {
    width: 100%
}

.gallery-spacing-custom .elementor-image-gallery .gallery-icon {
    padding: 0
}

@media (min-width:768px) {
    .elementor-image-gallery .gallery-columns-2 .gallery-item {
        max-width: 50%
    }

    .elementor-image-gallery .gallery-columns-3 .gallery-item {
        max-width: 33.33%
    }

    .elementor-image-gallery .gallery-columns-4 .gallery-item {
        max-width: 25%
    }

    .elementor-image-gallery .gallery-columns-5 .gallery-item {
        max-width: 20%
    }

    .elementor-image-gallery .gallery-columns-6 .gallery-item {
        max-width: 16.666%
    }

    .elementor-image-gallery .gallery-columns-7 .gallery-item {
        max-width: 14.28%
    }

    .elementor-image-gallery .gallery-columns-8 .gallery-item {
        max-width: 12.5%
    }

    .elementor-image-gallery .gallery-columns-9 .gallery-item {
        max-width: 11.11%
    }

    .elementor-image-gallery .gallery-columns-10 .gallery-item {
        max-width: 10%
    }
}

@media (min-width:480px) and (max-width:767px) {

    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
        max-width: 50%
    }
}

@media (max-width:479px) {

    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
        max-width: 100%
    }
}

.online-sabong-bg {
    background-color: #514D7C;
}
/* popular games end */