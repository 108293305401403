

#main-header .header-button {
    margin: 5px 0px 5px 0px;
    color: var(--global-palette3);
    border: 2px none transparent;
    box-shadow: 0px 0px 0px -7px rgba(0, 0, 0, 0);
  }
  
  #main-header .header-button:hover {
    color: #ed6565;
    background: #ffe7e7;
    box-shadow: 0px 15px 25px -7px rgba(0, 0, 0, 0.1);
  }

  .mobile-header-button-wrap .mobile-header-button-inner-wrap .mobile-header-button {
    color: var(--global-palette3);
    border: 2px none transparent;
    box-shadow: 0px 0px 0px -7px rgba(0, 0, 0, 0);
}

.mobile-header-button-wrap .mobile-header-button-inner-wrap .mobile-header-button:hover {
    color: var(--global-palette1);
    background: var(--global-palette3);
    box-shadow: 0px 15px 25px -7px rgba(0, 0, 0, 0.1);
}
