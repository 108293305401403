body {
    background-color: #221e5b;
    color: white;

}

.noPage {
    height: 73vh;
}
.elementor-71 .elementor-element.elementor-element-614dbe57:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-71 .elementor-element.elementor-element-614dbe57>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #221e5b;
    color: white;
    margin-top: -60px
}